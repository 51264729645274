@import 'styles/variables';

.p-focus {
  box-shadow: none;
}

.p-disabled {
  opacity: 0.35;
}

.p-inputtext:enabled:hover {
  border-color: $grey-light;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: var(--input-focus);
}

.p-inputtext {
  height: $input-height;
  font-family: $primary-font;
  font-size: $default-font-size;
  color: $black-normal;
  background: #ffffff;
  padding: 8px 8px;
  border: 1px solid $grey-light;
  border-radius: $input-radius;
}

.p-component {
  font-family: $primary-font;
  font-size: $default-font-size;
  font-weight: normal;
}

.p-button {
  color: $black-normal;
  background: $white-light;
  border: 1px solid $grey-light;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: $input-radius;
}

.p-button.p-button-icon-only {
  padding: 0;
}

.p-button:focus {
  box-shadow: none;
}

.p-button:enabled:hover,
.p-button:enabled:active {
  background: $grey-blue-lighter;
  color: $black-normal;
  border-color: $grey-light;
}

// percentage suffix for forms like widthRatio
.nl-suffix-percent {
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white-light;
  border-top: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
}
