@font-face {
  font-family: 'nl-iconfont';
  src: url('fonts/nl-iconfont.ttf?3ug7u1') format('truetype'),
    url('fonts/nl-iconfont.woff?3ug7u1') format('woff'),
    url('fonts/nl-iconfont.svg?3ug7u1#nl-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='i-'],
[class*=' i-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nl-iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-auto:before {
  content: '\e900';
}
.i-page:before {
  content: '\e901';
}
.i-x:before {
  content: '\e902';
}
.i-add:before {
  content: '\e903';
}
.i-announcement:before {
  content: '\e904';
}
.i-arrow-left:before {
  content: '\e905';
}
.i-arrow-right:before {
  content: '\e906';
}
.i-automation:before {
  content: '\e907';
}
.i-campaign:before {
  content: '\e908';
}
.i-cursor-click:before {
  content: '\e909';
}
.i-dashboard:before {
  content: '\e90a';
}
.i-data:before {
  content: '\e90b';
}
.i-empty-campaign:before {
  content: '\e90c';
}
.i-empty-draft:before {
  content: '\e90d';
}
.i-empty-group:before {
  content: '\e90e';
}
.i-empty-schedule:before {
  content: '\e90f';
}
.i-empty-segment:before {
  content: '\e910';
}
.i-empty-serach:before {
  content: '\e911';
}
.i-file:before {
  content: '\e912';
}
.i-group:before {
  content: '\e913';
}
.i-invoice:before {
  content: '\e914';
}
.i-mail-open:before {
  content: '\e915';
}
.i-mail:before {
  content: '\e916';
}
.i-minus:before {
  content: '\e917';
}
.i-msg-question:before {
  content: '\e918';
}
.i-payment:before {
  content: '\e919';
}
.i-plane:before {
  content: '\e91a';
}
.i-surenotify:before {
  content: '\e91b';
}
.i-template:before {
  content: '\e91c';
}
.i-landing-page-empty:before {
  content: '\e91e';
}
.i-automation-page-empty:before {
  content: '\e91f';
}
