@import 'styles/variables';

@mixin cardBase($bgColor, $color) {
  background-color: $bgColor;
  color: $color;
  border-radius: $radius;
}

@mixin card($bgColor: white, $color: $black-normal, $padding: $block-gutter-m) {
  @include cardBase($bgColor, $color);
  padding: $padding;
}
