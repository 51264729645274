@import 'styles/variables';

$step-number-size: 32px;

.p-steps-item {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}

.p-steps .p-steps-item .p-menuitem-link {
  border-radius: 0;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-top: 0;
  padding-right: 60px;
}

.p-steps .p-steps-item:before {
  display: none;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0;
  color: var(--step-grey);
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  border: none;
  background: transparent;
  min-width: $step-number-size;
  width: $step-number-size;
  height: $step-number-size;
  line-height: $step-number-size;
  font-size: 18px;
  background: var(--step-grey);
  margin-right: 8px;
  color: white;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background-color: var(--primary-light);
  color: white;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: normal;
  color: var(--primary-light);
}

.steps-tab {
  margin-bottom: 24px;
}
