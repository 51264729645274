@import 'styles/variables';
$popover-gap: 12px;

.dnd-popover.nl-popover {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $grey-blue;
}

.dnd-popover.nl-popover-left {
  margin-right: $popover-gap;
}
.dnd-popover.nl-popover-top {
  margin-bottom: $popover-gap;
}
.dnd-popover.nl-popover-right {
  margin-left: $popover-gap;
}
.dnd-popover.nl-popover-bottom {
  margin-top: $popover-gap;
}

.dnd-popover.nl-popover.nl-popover__top_56 {
  margin-top: 56px;
}
