@import 'styles/variables';

.heading-bold,
.heading-m-bold,
.subheading-bold,
.subheading-m-bold,
.p-bold,
.p-l-bold {
  font-weight: $font-weight-bold;
}

.heading,
.heading-bold {
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 1px;
}

.heading-m,
.heading-m-bold {
  font-size: 28px;
  line-height: 1.4;
}

.subheading,
.subheading-bold {
  font-size: 24px;
  line-height: 1.4;
}

.subheading-m,
.subheading-m-bold {
  font-size: 18px;
  line-height: 1.4;
}

.button-text {
  font-size: 14px;
  line-height: 1.4;
}

.button-text-l {
  font-size: 17px;
  line-height: 1.4;
}

.p-l,
.p-l-bold {
  font-size: 16px;
  line-height: 1.5;
}

.p,
.p-bold {
  font-size: $default-font-size;
  line-height: 1.5;
}

.p-s {
  font-size: 14px;
  line-height: 1.5;
}

.hint-text {
  color: $grey-normal;
}

.warn-text {
  color: $warning;
}

.bold-text {
  font-weight: $font-weight-bold;
}

.center-text {
  text-align: center;
}

.code-text {
  border-radius: $radius;
  font-family: Courier;
  font-size: 13px;
  word-break: break-all;
  padding: 8px;
  background-color: $white-light;
  color: $black-light;
}

.link-text {
  display: inline-block;
  cursor: pointer;
  color: var(--primary);
  text-decoration: underline;

  &:hover {
    color: $purple;
  }
}

.btn-normal-text {
  color: $nl-company-primary;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: $purple;
  }
}

.btn-normal-grey {
  color: $grey-normal;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: $purple;
  }
}

.btn-normal-black {
  color: $black-normal;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: var(--primary);
  }
}
