@import 'styles/variables';

$checkbox-height: 17px;

// nomal
.p-checkbox {
  width: $checkbox-height;
  height: $checkbox-height;
}

.p-checkbox-label {
  font-weight: bold;
  cursor: pointer;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid $grey-light;
  background: #ffffff;
  width: $checkbox-height;
  height: $checkbox-height;
  color: var(--primary);
  border-radius: $input-radius;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: var(--primary);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-weight: bold;
  font-size: 10px;
}

// hightlight
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary);
  background: var(--primary);
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--primary-hover);
  background: var(--primary-hover);
}

// focus
.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: $grey-light;
}

.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus.p-highlight {
  box-shadow: none;
  border-color: var(--primary);
}

// TODO: disabled
// TODO: chkbox-label

// .theme-company {
//   .ui-chkbox-label:hover {
//     color: $nl-company-primary;
//   }
// }
