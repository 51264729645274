@import 'styles/variables';
$html-dnd-boundary: 2px dashed $grey-light;

.html-editor-container .mat-slider-thumb-label .mat-slider-thumb-label-text::after {
  content: '';
}

.style-config-text.disabled {
  opacity: 0.4;
  pointer-events: none;

  .input-wrapper.disabled,
  .input:disabled {
    background-color: white;
  }
}

.snippet-placeholder {
  border: $html-dnd-boundary;
  border-radius: 4px;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
