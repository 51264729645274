@import 'styles/variables';

.mat-slide-toggle-label {
  display: inline-flex !important;
}

.mat-slide-toggle-thumb-container {
  cursor: pointer !important;
}

// DD column layout, table config
.nl-column-layout,
.nl-table-config,
.nl-table-quick-config,
.nl-table-body-quick-config {
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #93c1e2;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: $blue-dark;
  }
}

.mat-mdc-slide-toggle {
  height: 21px !important;
}

.mdc-switch__ripple {
  display: none;
}

.mdc-switch__track::before {
  background-color: rgba(0, 0, 0, 0.38) !important;
}

.mdc-switch__track::after {
  background: var(--primary) !important;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: $black-normal;
}

.mdc-switch__shadow {
  background-color: #fff !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  fill: #fff !important;
  border-radius: 100%;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: #3f7dbd !important;
  width: 100% !important;
  height: 100% !important;
  background-color: #3f7dbd;
  border-radius: 100%;
}

.mdc-switch__track::after {
  background: #93c1e2 !important;
}
