.mce-tinymce {
  box-shadow: none !important;
}

.mce-top-part::before {
  box-shadow: none !important;
}

#editor_ifr {
  height: calc(100vh - 275px) !important;
}

.mce-ico.mce-i-code {
  width: 30px !important;
}

.mce-i-code:before {
  content: 'HTML' !important;
  font-size: 12px !important;
  font-weight: bold !important;
}
