@import './nl-palette';

$block-gutter-l: 40px;
$block-gutter-m: 32px;
$block-gutter-s: 24px;
$block-gutter-xs: 16px;
$block-gutter-xxs: 8px;

$radius: 4px;
$input-radius: 2px;
$input-height: 34px;
$nav-height: 50px;

$radio-size: 20px;
$radio-label-gap: 8px;

$primary-font: 'Helvetica Neue', Helvetica, 'PingFang TC', '微軟正黑體', Arial, 'Microsoft JhengHei',
  sans-serif;
$primary-font-string: '"Helvetica Neue", Helvetica, "PingFang TC", "微軟正黑體", Arial, "Microsoft JhengHei", sans-serif';
// because theme font must be string

$default-font-size: 15px;
$button-font-size: 14px;
$default-font-weight: normal;
$font-weight-bold: 600;

//
// breakpoint
//
$b-large: 1440px;
$b-medium: 1280px;
$b-desktop: 1024px;
$b-small: 850px;
$b-tablet: 767px;
$b-mobile: 550px;

$a-large: 1440px;
$a-medium: 1281px;
$a-desktop: 1025px;
$a-small: 851px;
$a-tablet: 768px;
$a-mobile: 551px;

$header-height: 84px;
$button-height: 34px;
