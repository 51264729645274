@import 'styles/variables';
@import 'styles/mixins';

.card {
  @include card;
}

.wide-card {
  @include card($padding: 24px 0);
}

.narrow-card {
  @include card($padding: 0);
}

.card--small {
  @include card($padding: 16px 24px);
  min-height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-min-height {
  min-height: 388px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold,
b {
  font-weight: bold;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-break {
  word-break: break-all;
}

.pointer {
  cursor: pointer;
}

.mb-m {
  margin-bottom: $block-gutter-m;
}

.mb-l {
  margin-bottom: $block-gutter-l;
}

.mb-s {
  margin-bottom: $block-gutter-s;
}

.mb-xs {
  margin-bottom: $block-gutter-xs;
}

.mb-xxs {
  margin-bottom: $block-gutter-xxs;
}

.mt-m {
  margin-top: $block-gutter-m;
}

.mt-l {
  margin-top: $block-gutter-l;
}

.mt-s {
  margin-top: $block-gutter-s;
}

.mt-xs {
  margin-top: $block-gutter-xs;
}

.mt-xxs {
  margin-top: $block-gutter-xxs;
}

.mr-xs {
  margin-right: $block-gutter-xs;
}

.pl-m {
  padding-left: $block-gutter-m;
}

.pr-m {
  padding-right: $block-gutter-m;
}

.pl-s {
  padding-left: $block-gutter-s;
}

.pr-s {
  padding-right: $block-gutter-s;
}

.pl-xs {
  padding-left: $block-gutter-xs;
}

.pr-xs {
  padding-right: $block-gutter-xs;
}

.pr-xxs {
  padding-right: $block-gutter-xxs;
}

.page-main-content {
  padding: $block-gutter-m $block-gutter-l 64px $block-gutter-l;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.relative {
  position: relative;
}

.status-warn {
  color: $warning;
}

.status-reward {
  color: $green-blue;
}
