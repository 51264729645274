@import 'styles/variables';

$active-color: var(--primary);
$border-color: $grey-light;
$bg-color: white;
$hover-bg-color: $grey-blue-bg;
$widget-radius: $input-radius;
$placeholeder-color: $grey-light;

.p-dropdown {
  width: 100%;
  background: #ffffff;
  border: 1px solid $grey-light;
  border-radius: $widget-radius;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: $grey-light;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: $border-color;
}

.dropdown-container {
  position: relative;
  height: $input-height;
}

.p-dropdown .p-dropdown-label {
  padding-left: 8px;
  border-radius: $widget-radius;
  display: flex;
  align-items: center;
  height: calc(#{$input-height} - 2px);
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: $placeholeder-color;
}

.p-dropdown .p-dropdown-trigger {
  border-left: 0;
  border-radius: $widget-radius;
  display: flex;
  align-items: center;
  color: $grey-normal;

  .p-dropdown-trigger-icon {
    color: $grey-normal;
    font-size: 13px;
    margin-top: 2px;
  }
}

.p-dropdown .p-dropdown-panel {
  box-shadow: none;
  border: 1px solid $border-color;
  border-radius: 0 0 $widget-radius $widget-radius;
  overflow: hidden;
  left: -1px !important;
  right: -1px !important;

  .p-dropdown-list {
    padding: 0;
    .p-dropdown-item {
      padding: 0;
    }
  }
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0;
}
.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
  padding: 0;
}

// item group
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 8px;
  color: $black-normal;
  border-top: 1px solid $border-color;
}
.p-dropdown-panel .p-dropdown-items .dropdown-option--group {
  padding-left: 24px !important;
}

// item reset
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0;
  color: $black-normal;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: inherit;
  background: none;
}

// item types
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  .dropdown-option--hr {
    height: 1px;
    background-color: $grey-light;
  }
  .dropdown-option--item {
    padding: 8px;
    color: $black-normal;
    background: transparent;
    transition: background-color 0.25s;
  }
  .dropdown-option--action {
    padding: 8px;
    font-weight: $font-weight-bold;
  }
  .dropdown-option--label {
    padding: 8px;
    cursor: default;
  }
}

// item hover
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  .dropdown-option--item {
    color: inherit;
    background: $hover-bg-color;
  }
  .dropdown-option--action {
    color: $active-color;
  }
}

// item highlight
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $black-normal;
  background: white;

  .dropdown-option--item {
    color: $active-color;
    background-color: $bg-color;
    border-color: $bg-color;
  }
}

// icon
.p-dropdown .p-dropdown-clear-icon {
  color: $grey-normal;
  transition: color 0.25s;
  right: 32px;
  font-size: 10px;
  margin-top: -4px;
  font-weight: bold;

  &:hover {
    color: $black-normal;
  }
}

// search header
.p-dropdown-panel .p-dropdown-header {
  padding: 8px;
  color: $black-normal;
  background: $white-light;
  border-top-right-radius: $widget-radius;
  border-top-left-radius: $widget-radius;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  color: $grey-normal;
}

// TODO: when loading
// .dropdown-container .loading-mask {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: $grey-blue-bg-darken;
//   padding-right: 25px;
//   border-radius: $widget-radius;
// }

// sms text import dropdown
.nl-sms-content .sms-text {
  .dropdown-option--item {
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: $black-normal;
  }
}

.signup-param-dropdown {
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: $black-normal;
  }
}
// email, signup page dnd block dropdown
.nl-email-links-selector {
  .link-type-dropdown {
    .p-dropdown {
      border-right: 0 !important;
    }
  }
}
.nl-email-links-selector,
.nl-page-links-selector,
.nl-form-field-selector {
  .link-type-dropdown {
    .p-dropdown {
      border-radius: 2px 0 0 2px;
    }
  }

  .anchor-dropdown,
  .page-url-dropdown {
    .p-dropdown-label {
      display: inline-block;
      width: 1px;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dropdown-option--item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .p-dropdown {
      border-radius: 0 2px 2px 0;
    }
  }

  .page-url-dropdown {
    .p-dropdown-item-group {
      padding: 16px 16px 4px 16px;
    }
    .p-dropdown-item-group:first-child {
      border-top: 0;
    }

    .p-dropdown-item-group:not(:first-child) {
      margin-top: 8px;
    }

    .p-dropdown-panel {
      padding-bottom: 8px;
    }
  }
}

.nl-ellipsis-dropdown-item {
  .p-dropdown-label {
    display: inline-block;
    width: 1px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-option--item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-dropdown {
    border-radius: 0 2px 2px 0;
  }
}

body > .p-dropdown-panel {
  min-width: fit-content;
  z-index: 9999 !important;
  // remove dropdown style  width: 380px; in production
  width: 380px;
}

body > .nl-trigger-type-dropdown {
  width: 380px;
}

body > .nl-form-field-selector-dropdown {
  width: 100px;
}
