@import 'styles/variables';

.mdc-snackbar__surface {
  min-width: 226px !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) !important;
  background: white !important;
  border: none !important;
}

.mat-mdc-snack-bar-label {
  color: $black-normal !important;
}
