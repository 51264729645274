@import 'styles/variables';

.p-colorpicker {
  margin-right: 8px;
  .p-colorpicker-preview.p-inputtext {
    border: 1px solid $grey-light;
  }
}

.p-colorpicker-panel {
  background-color: white !important;
  border-color: white !important;
}

.p-colorpicker-panel.p-shadow {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
}
