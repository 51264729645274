@import 'styles/variables';

.cdk-overlay-container {
  z-index: 2000 !important; // sholud more than p-dropdown
}

.mat-mdc-dialog-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

$dialog-actions-padding: 16px;
$dialog-actions-padding-wide: 32px;
.mat-mdc-dialog-actions {
  padding: $dialog-actions-padding 0 !important;
  margin-bottom: -$dialog-actions-padding !important;

  @media (min-width: 1920px) {
    padding: $dialog-actions-padding-wide 0 !important;
    margin-bottom: -$dialog-actions-padding-wide !important;
  }
}

.mat-mdc-dialog-container {
  border-radius: 4px !important;
  color: $black-normal;
}

.mat-mdc-dialog-surface {
  padding: 40px;
}

.fixed-content-dialog {
  .mat-mdc-dialog-content {
    margin-left: -40px !important;
    margin-right: -40px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

$huge-dialog-margin-wide: 40px;
$huge-dialog-margin: 24px;
$huge-dialog-padding-y: 24px;
.huge-dialog {
  position: relative !important;

  .mat-mdc-dialog-container {
    overflow: initial !important;
    min-height: initial !important;
    max-height: 100% !important;
    position: absolute;
    width: auto !important;
    height: auto !important;
    top: $huge-dialog-margin;
    bottom: $huge-dialog-margin;
    padding: $huge-dialog-padding-y 40px !important;

    @media (min-width: 1920px) {
      top: $huge-dialog-margin-wide;
      bottom: $huge-dialog-margin-wide;
      padding: $huge-dialog-margin-wide !important;
    }
  }

  .mat-mdc-dialog-surface {
    background-color: $grey-blue-bg !important;
    padding: $huge-dialog-padding-y 40px;
  }

  .mat-mdc-dialog-content {
    height: 100% !important;

    max-height: calc(100vh - 300px) !important;
    margin-left: -40px !important;
    margin-right: -40px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.nl-modal-content-actions.huge-dialog .mat-mdc-dialog-content {
  max-height: calc(
    100vh - #{2 * $huge-dialog-margin + 2 * $huge-dialog-padding-y + 2 * $dialog-actions-padding +
      $button-height}
  ) !important;

  @media (min-width: 1920px) {
    max-height: calc(
      100vh - #{2 * $huge-dialog-margin-wide + 2 * $huge-dialog-padding-y + 2 *
        $dialog-actions-padding-wide + $button-height}
    ) !important;
  }
}

.fullscreen-dialog {
  .mat-mdc-dialog-surface {
    padding: 0 !important;
    border-radius: 0 !important;
  }
}

.edit-member-modal {
  .mat-mdc-dialog-container {
    background-color: $grey-blue-bg;
  }
}

.ga-view-modal {
  .mat-mdc-dialog-container {
    overflow: visible;
  }
}
