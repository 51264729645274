@import 'styles/variables';

.primeng-paginator {
  height: 100%;
  text-align: right;
  border: none;
  background: none;

  .ui-paginator-pages {
    height: 100%;
    padding: 0;

    .ui-paginator-page {
      height: 100%;
    }
  }

  a:focus {
    background-color: $grey-blue-bg-darken;
  }

  .ui-state-default {
    border: 0;
    background: none;
    padding: 4px 8px;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    width: auto !important;
  }

  .ui-paginator-next.ui-paginator-element,
  .ui-paginator-prev.ui-paginator-element {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
    background: $grey-blue-bg-darken !important;
    border: none !important;
    color: $black-normal !important;
  }

  .ui-paginator-pages > .ui-state-active {
    background: rgba(255, 255, 255, 0.8);
    color: var(--primary);
  }

  .ui-paginator-first :before {
    content: '\f100';
  }
  .ui-paginator-prev :before {
    content: '\f104';
  }
  .ui-paginator-next :before {
    content: '\f105';
  }
  .ui-paginator-last :before {
    content: '\f101';
  }
}
