@import 'styles/variables';

$site-notification-levels: info, warn, attention;
$site-notification-themes: outlined, contained;

$info-text: #000000;
$warn-text: #946665;
$attention-text: $yellow-text;

$info-icon: $green-blue;
$warn-icon: #f27472;
$attention-icon: $yellow-text;

$info-bg: #ffffff;
$warn-bg: #fdeded;
$attention-bg: $yellow-bg;

$info-contained-text: $blue-sky;
$warn-contained-text: #000000;
$attention-contained-text: $yellow-text;

$info-contained-icon: $blue-sky;
$warn-contained-icon: $warning;
$attention-contained-icon: $yellow-text;

$info-contained-bg: $blue-sky-bg-lighter;
$warn-contained-bg: $grey-blue-bg-darken;
$attention-contained-bg: $yellow-bg;
