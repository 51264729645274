// TODO: delete this file after change button.component support href link tag
@import 'styles/variables';

@mixin nl-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius;
  cursor: pointer;
  transition: background-color 0.25s;
}

//size
.nl-button-m {
  @include nl-button;
  height: $button-height;
  min-width: 100px;
  padding: 0 16px;
  letter-spacing: 1px;
}

.nl-button-ghost {
  color: var(--primary);

  &:hover {
    color: $purple;
  }
}
