@import 'styles/variables';

.mat-slider {
  padding: 0 !important;
}

.mdc-slider__track {
  top: calc(50% - 1px) !important;
  height: 6px !important;
}

.mat-mdc-slider {
  .mdc-slider__track {
    .mdc-slider__track--inactive {
      height: 6px;
      top: 1px;
      opacity: 1;
    }

    .mdc-slider__track--active {
      height: 6px;
      top: 1px;
    }
  }
}

.nl-mat-slider-dark.mat-mdc-slider {
  .mdc-slider__track--inactive {
    background-color: $grey-blue;
  }
}
.nl-mat-slider-light.mat-mdc-slider {
  .mdc-slider__track--inactive {
    background-color: $grey-blue-bg;
  }
}

.mdc-slider .mdc-slider__value-indicator-container .mdc-slider__value-indicator {
  transform: scale(1);
  background-color: transparent !important;
  color: var(--primary) !important;
  opacity: 1 !important;

  .mdc-slider__value-indicator-text {
    font-size: 16px !important;
  }

  &::before {
    display: none !important;
  }

  &::after {
    content: '%' !important;
    display: inline-block;
    color: var(--primary);
    font-size: 16px !important;
    top: 5px !important;
    left: 30px !important;
    opacity: 1 !important;
  }
}

.mat-slider-horizontal .mat-slider-wrapper {
  left: 0 !important;
  right: 0 !important;
}

.mat-slider-thumb-label {
  background-color: transparent !important;
  position: relative;
  transform: none !important;
  width: 50px !important;
  border-radius: 0 !important;

  .mat-slider-thumb-label-text {
    opacity: 1 !important;
    position: absolute;
    left: 13px;
    top: 0;
    z-index: 2;
    color: var(--primary);
    transform: scale(1) !important;
    font-size: 16px;
    position: relative;

    &::after {
      content: '%';
      display: inline-block;
      color: var(--primary);
      font-size: 16px;
    }
  }
}

.mat-slider-thumb {
  transform: scale(1) !important;
  background: var(--primary) !important;
}

.mat-slider-track-fill {
  transition-property: transform !important;
  transition-duration: 0.3s !important;
  transition-delay: 0 !important;
  transition-timing-function: linear !important;
}

// page dd avatar
.nl-dnd-avatar-config {
  .mdc-slider {
    margin-right: 4px;
  }

  .mdc-slider__value-indicator-container {
    display: none;
  }
}

// page dd block layout config
.nl-dnd-blocks-layout-config {
  .mdc-slider .mdc-slider__value-indicator-container {
    bottom: 30px;
  }
  .mdc-slider .mdc-slider__value-indicator {
    transform: scale(0);

    &::after {
      content: '' !important;
    }
  }

  .mdc-slider__tick-marks {
    display: none !important;
  }
}
