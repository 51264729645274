$black-normal: #555555;
$black-light: #666666;

$grey-normal: #999999;
$grey-light: #cccccc;

$white-light: #f8f8f8;
$white-dark: #efefef;

$grey-blue-bg: #edeff2;
$grey-blue-bg-light: #f5f7fa;
$grey-blue-bg-darken: #e1e5ea;
$grey-blue: #d6dadf;
$grey-blue-darken: #b5bbc3;
$grey-blue-lighter: #e7e9ec;
$grey-blue-text: #8d96a1;
$silver: #707c8c;

$purple: #5525a5;
$purple-bg: #dfd8f2;
$indigo: #3b529f;
$blue-sky: #4ca0d7;
$blue-dark: #3f7dbd;
$blue-dark-hover: #2a68a7;
$blue-sky-bg: #c7e4f7;
$blue-sky-bg-lighter: #dbebf7;

$orange: #ff6633;
$orange-hover: #de5d32;
$orange-gradient: #f7af6c;
$orange-light: #ff8a63;
$orange-bg: #ffe3dc;
$orange-bg-light: #fff2ee;
$orange-bg-darken: #ffd0c3;

$nl-company-primary: $indigo;

$yellow: #ffbe14;
$yellow-bg: #faf7e7;
$yellow-text: #d39a02;
$green: #46c360;
$green-bg: #d4ece6;

$warning: #ed5555;
$warning-darken: #c73030;
$warning-bg: #fcbbbb;

$green-blue: #45bbb5;
$green-blue-hover: #309e99;

//social media colors
$facebook: #3b579d;
$blue-google: #5477eb;

:root {
  --primary: #{$blue-dark};
  --primary-light: #{$blue-sky};
  --primary-hover: #{$blue-dark-hover};
  --primary-gradient: #{$orange-gradient};
  --body-bg-color: #{$grey-blue-bg};
  --step-grey: #{$grey-normal};
  --primary-bg: #{$grey-blue};
  --primary-bg-darken: #{$grey-blue-darken};
  --primary-bg-light: #{$orange-bg-light};
  --input-focus: #{$blue-sky};
}
