@import 'styles/variables';

.mdc-radio {
  &:hover + .mdc-label {
    color: var(--primary) !important;
  }

  + .mdc-label:hover {
    color: var(--primary) !important;
    cursor: pointer;
  }

  .mdc-radio__outer-circle {
    border-color: $grey-light !important;
    border-width: 1px !important;
    background-color: white !important;
  }

  .mdc-radio__background::before {
    display: none;
  }

  &.mdc-radio--disabled {
    pointer-events: none;

    & + .mdc-label {
      pointer-events: none;
    }

    .mdc-radio__outer-circle {
      border-color: $grey-light !important;
      background-color: $grey-light !important;
      opacity: 1 !important;
    }

    .mdc-radio__inner-circle {
      border-color: $grey-normal !important;
      background-color: $grey-normal !important;
      opacity: 1 !important;
    }
  }
}

.coupon-item {
  .mdc-form-field {
    width: 100%;
  }

  .mdc-label {
    width: 100%;
  }
}

.mat-mdc-radio-button .mdc-form-field {
  color: $black-normal !important;
}

.mat-mdc-radio-checked {
  .mdc-radio__inner-circle {
    transform: scale(0.6) !important;
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
  }

  .mdc-radio__outer-circle {
    border-color: var(--primary) !important;
  }
}

.mat-mdc-radio-label {
  display: flex !important;
  align-items: flex-start !important;
  width: 100%;
  white-space: normal !important;
}

.mat-mdc-radio-container {
  background-color: blue;
  flex: 0 0 20px;
}

.mat-mdc-radio-label-content {
  color: $black-normal !important;
  transition: color 0.25s;
  flex: 1 1 auto;
}

.mat-mdc-radio-disabled .mat-mdc-radio-label-content {
  color: $grey-normal !important;
}

.mat-mdc-radio-group--table {
  .mat-mdc-radio-label {
    align-items: center !important;
  }
}

.mdc-radio {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 4px !important;
}

// page dd avatar
.nl-dnd-avatar-config {
  .mdc-radio {
    opacity: 0;
  }
}
