@import 'styles/variables';

.dnd-file-upload-container {
  background: white;
  border: 1px dashed $grey-light;
  border-radius: $radius;
  padding: 24px;
  min-height: 200px !important;

  &.over {
    border-color: var(--primary);
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .icon-file {
    flex: 0 0 64px;
    font-size: 64px;
    color: $grey-blue;
    transition: color 0.25s;
  }

  &.uploaded .icon-file {
    color: $blue-sky;
  }

  &.warning .icon-file {
    color: $warning-bg;
  }

  .b-content {
    flex: 0 0 auto;
    margin-left: 24px;

    &.full {
      flex: 1;
    }
  }
}
