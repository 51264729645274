@import 'styles/variables';

$active-color: var(--input-focus);
$border-color: $grey-light;
$hover-bg-color: $grey-blue-bg;

p-multiSelect {
  display: block;
  width: 200px;
}

.p-multiselect {
  width: 100%;
  border: 1px solid $grey-light;
  border-radius: $input-radius;
  height: $input-height;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: $border-color;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border: 1px solid $active-color;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: $grey-light;
}

.p-multiselect-panel {
  color: $black-normal;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: $black-normal;
  padding: 8px;
  transition: background-color 0.25s;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: transparent;
  color: $black-normal;
}

.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: $black-normal;
  background: $hover-bg-color;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}

.p-multiselect {
  .p-multiselect-label {
    padding: 0 8px;
    line-height: 32px;
  }

  .p-multiselect-trigger {
    color: $grey-normal;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    .p-multiselect-trigger-icon {
      color: $grey-normal;
      font-size: 13px;
      margin-top: 2px;
    }
  }
}
