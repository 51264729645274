@import 'styles/variables';

$table-bg: transparent;
$table-border-color: $grey-blue;
$td-hieght: 48px;
$th-hieght: 56px;

@mixin emptyState() {
  height: 336px;
  text-align: center !important;
  color: $grey-normal;
  vertical-align: middle;
  font-size: 17px;
  letter-spacing: 0.05em;
  border: 0 !important;
  width: 100%;
  justify-content: center;
}
@mixin td() {
  font-size: $default-font-size;
  color: $black-normal;
  padding: 12px;
  vertical-align: middle;
}
@mixin th() {
  font-size: $default-font-size;
  color: $black-normal;
  padding: 0 12px;
  height: $th-hieght;
  vertical-align: middle;
  line-height: 1.2;
}
@mixin v-top {
  &.v-top {
    vertical-align: top;
  }
}

.p-datatable-thead {
  tr:first-child {
    th:first-child {
      border-top-left-radius: $radius;
    }
    th:last-child {
      border-top-right-radius: $radius;
    }
  }
}
.p-datatable-tbody {
  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $radius;
    }
    td:last-child {
      border-bottom-right-radius: $radius;
    }
  }
}

//nl-table
// table border bottom
.nl-table-1 {
  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td {
    border-bottom: 1px solid $table-border-color !important;
  }

  .ui-table .ui-table-tfoot > tr:last-child > td,
  .p-datatable-tbody > tr:last-child > td {
    border-bottom: none !important;
  }

  .p-datatable-thead > tr > th {
    @include th;
  }

  .p-datatable-tbody > tr > td {
    @include td;
    @include v-top;
  }

  .p-datatable-thead > tr > th:first-child,
  .p-datatable-tbody > tr > td:first-child,
  first-child {
    padding-left: $block-gutter-m;
  }
  .p-datatable-thead > tr > th:last-child,
  .p-datatable-tbody > tr > td:last-child,
  last-child {
    padding-right: $block-gutter-m;
  }

  .p-datatable-tbody > tr > .ui-table-empty {
    @include emptyState;
  }
}

.text-url {
  color: $grey-normal;
}

.text-url,
.text-break {
  word-break: break-all;
  @extend .p-s;
}

// Fixed Contacts Email table
.nl-scrollableTable {
  .p-datatable-thead > tr,
  .p-datatable-tbody > tr {
    border-bottom: 1px solid $table-border-color;
  }

  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td {
    border-bottom: none;
  }

  .p-datatable-tbody > tr:last-child {
    border-bottom: none !important;
  }

  .p-datatable-thead > tr > th {
    @include th;
    font-weight: $font-weight-bold;
    background: $table-bg;
  }

  .p-datatable-tbody > tr > td {
    @include td;
    background: $table-bg;
  }

  .p-datatable-thead > tr > th:first-child,
  .p-datatable-tbody > tr > td:first-child {
    padding-left: $block-gutter-xs;
  }
  .p-datatable-thead > tr > th:last-child,
  .p-datatable-tbody > tr > td:last-child {
    padding-right: $block-gutter-xs;
  }

  .p-datatable-table .p-frozen-column {
    background-color: white;

    &:first-child {
      border-top-left-radius: $radius;
    }

    &.last-frozen-col {
      border-right: 1px solid $table-border-color;
    }
  }

  .p-datatable-frozen-view {
    .ui-table-empty {
      visibility: hidden;
    }
  }
  .ui-table-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    @include emptyState;
  }
}

// 訂單記錄 table
.nl-table-1 {
  min-width: 400px;
  th,
  td {
    border-bottom: 1px solid $table-border-color;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  tr:last-child th,
  tr:last-child td {
    border-bottom: 0;
  }
  th {
    font-weight: $font-weight-bold;
    min-width: 96px;
  }
  .tr-clickable {
    cursor: pointer;
    &:hover {
      background-color: $grey-blue-bg-light;
      td {
        background-color: $grey-blue-bg-light;
      }
    }
  }
}

// ATM 資訊 table
.nl-table-2 {
  th,
  td {
    padding-bottom: 12px;
  }
  th {
    font-weight: $font-weight-bold;
    min-width: 96px;
  }
}

// 購買方案 table
.nl-table-3 {
  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    font-family: 'PingFang TC';
    color: $black-normal;
    font-weight: 400;
  }
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    border-bottom-color: $grey-blue;
  }
  .p-datatable-thead > tr > th {
    border: none;
    background-color: #fff;
    font-weight: $font-weight-bold;
  }
  .p-datatable-tbody > tr .subject {
    font-weight: $font-weight-bold;
  }
  .p-datatable-tbody > tr .quantity {
    width: 44px;
    height: 34px;
    border: 1px solid $grey-light;
    padding: 5px;
  }
  .p-datatable-tbody > tr .delete {
    color: $warning;
    cursor: pointer;
  }
  .p-datatable-tfoot > tr .subject {
    font-weight: $font-weight-bold;
  }
  .p-datatable-tfoot > tr .nt {
    font-size: 14px;
    padding-right: 24px;
  }
  .p-datatable-tfoot > tr .dolor {
    font-size: 24px;
  }
  .p-datatable-tfoot > tr > td {
    background-color: #fff;
  }
  .p-datatable-tfoot > tr:last-child > td {
    border: none;
  }
}

.nl-landing-page-report.outbound-links {
  .p-datatable-table {
    width: 100%;
  }
}
