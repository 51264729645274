// write all flexbox related styles here which was port to tailwindcss classnames
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
.flex-full {
  flex: 1 1 100%;
}

.flex-grow {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}
.flexshrink-0 {
  flex-shrink: 0;
}

.order-first {
  order: -9999;
}
.order-last {
  order: 9999;
}
.order-none {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.items-strertch {
  align-items: stretch;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}

.content-center {
  align-content: center;
}
.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.content-between {
  align-content: space-between;
}
.content-around {
  align-content: space-around;
}
.content-evenly {
  align-content: space-evenly;
}
.content-baseline {
  align-content: baseline;
}
.content-stretch {
  align-content: stretch;
}

.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.self-baseline {
  align-self: baseline;
}

.place-content-center {
  place-content: center;
}
.place-content-start {
  place-content: flex-start;
}
.place-content-end {
  place-content: flex-end;
}
.place-content-between {
  place-content: space-between;
}
.place-content-around {
  place-content: space-around;
}
.place-content-evenly {
  place-content: space-evenly;
}
.place-content-stretch {
  place-content: stretch;
}

.place-items-start {
  place-items: flex-start;
}
.place-items-end {
  place-items: flex-end;
}
.place-items-center {
  place-items: center;
}
.place-items-stretch {
  place-items: stretch;
}

.place-self-auto {
  place-self: auto;
}
.place-self-start {
  place-self: flex-start;
}
.place-self-end {
  place-self: flex-end;
}
.place-self-center {
  place-self: center;
}
.place-self-stretch {
  place-self: stretch;
}
