@import 'styles/variables';

body,
html {
  font-family: $primary-font;
  font-weight: $default-font-weight;
  color: $black-normal;
  font-size: $default-font-size;
  background-color: var(--body-bg-color);
  height: 100%;
  text-rendering: geometricPrecision;
}

html {
  &.cdk-global-scrollblock {
    position: initial;
  }
}

// reset list style
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

// reset table
table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: $default-font-weight;
  text-align: left;
  vertical-align: top;
}

td {
  vertical-align: top;
}

// reset heading padding
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
}

//reset a link
a {
  color: inherit;
  text-decoration: none;
  transition: color 0.25s, border 0.25s, background-color 0.25s;
}

a:focus {
  outline: 0;
}

//rest hr
hr {
  width: 100%;
  border: 0;
  height: 1px;
  background: $grey-blue;
  margin: 0;
}

//rest img
img {
  max-width: 100%;
  height: auto;
}

//reset-button
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  line-height: normal;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  transition: color 0.25s, background-color 0.25s;

  &:focus {
    outline: none;
  }
}

input,
label,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  outline: 0;
  font: inherit;
  -webkit-appearance: none;
  text-rendering: geometricPrecision;
}

input:focus,
textarea:focus {
  outline: 0 !important;
}

// disabled transition, transform animation for MatTabs
.mat-ripple-element {
  background: none !important;
  transform: none !important;
  transition: none !important;
}

// reset primeng ui style
.ui-widget,
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button,
.mat-mdc-dialog-title {
  font-family: $primary-font !important;
  font-weight: $default-font-weight !important;
}

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
  box-shadow: none !important;
}

.ui-widget-content {
  border: 1px solid $grey-light;
}

.ui-shadow {
  box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.3);
}

// reset primeng style
span.pi {
  display: inline-block;
  font-family: FontAwesome, 'primeicons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  width: 100%;
  vertical-align: top;
}
