@import 'styles/variables';

.p-autocomplete-panel {
  color: $black-normal;
  border-radius: $input-radius;
  box-shadow: none;
  border: 1px solid $grey-light;
}

.p-autocomplete {
  width: 100%;
  display: block;

  input {
    width: 100%;
  }
}
