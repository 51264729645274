@import 'styles/variables';

$hover-bg-color: $grey-blue-bg;
$dropdownShadow: 0 0 8px rgba(0, 0, 0, 0.25);
$panel-radius: $input-radius;

p-overlaypanel {
  display: block;
  width: 100%;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  display: none;
}

.p-overlaypanel {
  margin-top: 0 !important;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

.p-overlaypanel {
  background: #ffffff;
  color: $black-normal;
  border-radius: $panel-radius;
  box-shadow: $dropdownShadow;
}

// alert background such as sms composer content
.p-overlaypanel.alert {
  background-color: #ffe5e5;
}

// function menu dropdown
.menu-dropdown-panel {
  width: 150px;

  .p-overlaypanel {
    padding: 8px 0;
  }

  .menu-divider {
    background-color: $grey-blue-bg;
  }

  .item,
  .item-text {
    display: block;
    width: 100%;
    color: $black-normal;
    height: auto;
    line-height: normal;
    font-size: $default-font-size;
    padding: 8px 16px;
    font-weight: normal;
  }

  .item-disabled {
    background-color: $grey-blue-bg;
  }

  .item {
    &:focus:not(.item-disabled) {
      background-color: none;
    }
    &:hover:not(.item-disabled) {
      color: inherit;
      background: $white-light !important;
    }

    &.active {
      color: var(--primary);
      pointer-events: none;
    }
  }
}

// header user dropdown
.top-menu-dropdown-panel {
  width: 200px;
}

// header information dropdown
.tooltip-panel.p-overlaypanel .p-overlaypanel-content {
  padding: 10px 16px;
}

//campaign and sms list actions overlay panel position
.nl-campaign-list,
.nl-sms-list {
  .actions-overlay-placeholder .menu-dropdown-panel {
    top: 18px !important;
    left: -150px !important;
  }
}

// template management overlay panel position
.nl-template-management {
  .actions-overlay-placeholder .menu-dropdown-panel {
    top: -146px !important;
    left: -68px !important;
  }
}
