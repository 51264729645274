@import 'styles/variables';

$default-bg-gradient: linear-gradient(to right, $grey-blue-bg 8%, #e2e7ed 18%, $grey-blue-bg 33%);
$darken-bg-gradient: linear-gradient(to right, $grey-blue-bg-darken 8%, #eceef0 18%, $grey-blue-bg-darken 33%);

@keyframes placeHolder-shimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

@mixin default-animated {
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolder-shimmer;
  animation-timing-function: linear;
  border-radius: 2px;
}

@mixin default-animated-size {
  background-size: 1000px 9px;
  margin-top: 6px;
  margin-bottom: 6px;
  display: block;
}

@mixin grey-blue-bg {
  background: $grey-blue-bg;
  background: $default-bg-gradient;
}

@mixin grey-blue-bg-darken {
  background-color: $grey-blue-bg-darken;
  background: $darken-bg-gradient;
}

@mixin btn-loader {
  display: block;
  height: 34px;
  background-color: $grey-blue-bg-darken;
  border-radius: $radius;
  width: 68px;
  border-radius: $radius;
}
