@import 'styles/variables';

.mat-mdc-tab,
.mat-mdc-tab-link {
  min-width: 100px !important;

  &:hover,
  &.mdc-tab--active {
    .mat-mdc-tab-ripple {
      background-color: #edeff2 !important;
    }
    .mdc-tab__text-label {
      color: var(--primary) !important;
    }
  }

  .mdc-tab__text-label {
    color: $black-normal !important;
    height: 40px !important;
    font-weight: $font-weight-bold !important;
    letter-spacing: 1px !important;
    transition: color 0.25s;
  }
}

.mat-mdc-tab .mdc-tab__ripple::before {
  background-color: transparent !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  background-color: var(--primary) !important;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border: 0 !important;
}

// FIXME: 不確定哪裡有用到 原始碼中也搜尋不到 datatable-tabs
.datatable-tabs {
  .mat-tab-labels {
    background-color: $grey-blue !important;
  }

  .mat-tab-label {
    background-color: $grey-blue !important;
    height: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    transition: background-color 0.25s;

    &:hover:not(.mat-tab-label-active) {
      color: $black-normal !important;
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }

  .mat-tab-label-active {
    background-color: white !important;
    color: $black-normal !important;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-label:not(.mat-tab-disabled):focus,
  .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: transparent !important;
  }

  .mat-tab-label-active:not(.mat-tab-disabled):focus {
    background-color: white !important;
  }

  .mat-tab-header-pagination {
    background-color: $grey-blue !important;
  }
}
