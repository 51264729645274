.html-editor {
  a {
    display: inline-block;
    cursor: pointer;
    color: var(--primary);
    border-bottom: 1px solid var(--primary);

    &:hover {
      color: $purple;
      border-color: $purple;
    }
  }
  ol {
    margin-left: 1.5em;

    > li {
      list-style: decimal;
    }
  }

  ul {
    margin-left: 1.5em;

    > li {
      list-style: disc;
    }
  }
}
