@use 'sass:map';

@import 'styles/variables';
@import '../variables';

.site-notification-root {
  display: flex;
  padding: 20px 24px;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .site-notification-content-wrapper {
    flex: 1 1 auto;
    margin-left: 16px;
  }

  .site-notification-btn-close-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

@mixin head-icon-color($info-color, $warn-color, $attention-color) {
  $level-color-map: (
    'info': $info-color,
    'warn': $warn-color,
    'attention': $attention-color,
  );

  @each $level in $site-notification-levels {
    $color: map.get($level-color-map, $level);
    svg.site-notification-head-icon-#{$level} {
      fill: $color;
    }

    .site-notification-head-icon-#{$level} > svg {
      fill: $color;
    }

    i.site-notification-head-icon-#{$level} {
      color: $color;
    }

    .site-notification-head-icon-#{$level} > i {
      color: $color;
    }
  }
}

.site-notification-outlined-theme {
  @include head-icon-color($info-icon, $warn-icon, $attention-icon);
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: $radius;

  &.site-notification-info {
    border-color: $info-icon;
  }
  &.site-notification-warn {
    border-color: $warn-icon;
  }
  &.site-notification-attention {
    border-color: $attention-icon;
  }
}

.site-notification-contained-theme {
  @include head-icon-color($info-contained-icon, $warn-contained-icon, $attention-contained-icon);

  border-width: 1px;
  border-radius: $radius;

  &.site-notification-info {
    background-color: $info-contained-bg;
  }

  &.site-notification-warn {
    background-color: $warn-contained-bg;
  }

  &.site-notification-attention {
    background-color: $attention-contained-bg;
  }

  .site-notification-text-contained-info {
    color: $info-contained-text;
    * {
      color: $info-contained-text;
    }
  }

  .site-notification-text-contained-warn {
    color: $warn-contained-text;
    * {
      color: $warn-contained-text;
    }
  }

  .site-notification-text-contained-attention {
    color: $attention-contained-text;
    * {
      color: $attention-contained-text;
    }
  }
}
