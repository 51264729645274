@import 'styles/variables';

.col-4 {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  .col-item {
    flex: 0 0 calc((100% - #{$block-gutter-s} * 3) / 4);
    margin-bottom: $block-gutter-s;

    @media (max-width: 1280px) {
      flex: 0 0 calc((100% - #{$block-gutter-s}) / 2);
    }
  }
  .col-item:not(:last-child) {
    margin-right: $block-gutter-s;
  }

  @media (max-width: 1280px) {
    .col-item:nth-child(2n) {
      margin-right: 0;
    }
  }
}
