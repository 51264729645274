@import 'styles/variables';

$icon-width: 3em;

.p-calendar {
  .p-button {
    border-radius: 0 $input-radius $input-radius 0;
    border-left: 0;
  }
}

.p-calendar {
  width: 100%;
}

.p-datepicker {
  padding: 8px;
  background: #ffffff;
  color: $black-normal;
  border: 1px solid $grey-light;
  border-radius: $input-radius;
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker:not(.p-datepicker-inline) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

.p-datepicker table td.p-datepicker-today > span {
  background: $grey-blue-bg;
  color: $black-normal;
  border-color: transparent;
}

// hover
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: $black-normal;
  background: $grey-blue-lighter;
}
// focus
.p-datepicker table td > span:focus {
  box-shadow: none;
}
// selected
.p-datepicker table td > span.p-highlight {
  background: var(--primary);
  color: white;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  color: $black-light;
}

// today
.p-datepicker table td.p-datepicker-today > span {
  background: transparent;
  font-weight: bold;
  color: var(--primary);
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: none;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  background: var(--primary);
}

.p-calendar input.form-invalid {
  border-color: $warning;
}
